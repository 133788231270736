<template>
  <div class="search-condition-wrap">
    <div class="button-wrap">
      <el-button type="primary" style="width: 110px" @click="openDialog('searchStoreGift')">修改條件</el-button>
    </div>
    <el-descriptions :column="3" size="large" style="width: 100%" v-if="getSearchCondition">
      <el-descriptions-item label-class-name="descriptions-label" label="活動ID">
        {{ getSearchCondition.event_id || '未設定' }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" label="兌換日期">
        {{ getDateText(getSearchCondition.date) }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" label="消費者類型">
        {{
          getSearchCondition?.customer_type
            ? consumer_options.filter((data) => data.value == getSearchCondition.customer_type)[0].label
            : '未設定'
        }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" label="活動名稱">
        {{ getSearchCondition.event_name || '未設定' }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" label="交易序號">
        {{ getSearchCondition.redeem_id || '未設定' }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" label="兌換品名稱">
        {{ getSearchCondition.gift || '未設定' }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" class-name="descriptions-content" label="門市">
        {{
          getSearchCondition?.shop_id
            ? getSearchCondition.shop_id.split(' ')[1] + ' ' + getSearchCondition.shop_id.split(' ')[2]
            : '未設定'
        }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" label="會員/旺卡 ID">
        {{ getSearchCondition.member_id || '未設定' }}
      </el-descriptions-item>
      <el-descriptions-item label-class-name="descriptions-label" label="兌換情況">
        {{
          getSearchCondition?.redeem_status
            ? redeem_status_options.filter((data) => data.value == getSearchCondition.redeem_status)[0].label
            : '未設定'
        }}
      </el-descriptions-item>
    </el-descriptions>
  </div>

  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { useGetters } from '@/utils/mapStore';
import { defineAsyncComponent } from 'vue';
import { useDialog } from '@/utils/dialog';
import { mapAvailableStatus, consumer_options, redeem_status_options } from '@/utils/define';
import { getFormatDate } from '@/utils/datetime';

export default {
  name: 'search-store-gift',
  components: {
    SearchStoreGiftDialog: defineAsyncComponent(() => import('@/components/discount/SearchStoreGiftDialog.vue')),
  },
  setup() {
    //取得條件
    const getSearchCondition = useGetters('storeGiftRecord', ['getSearchCondition']);
    const getDateText = (arr) => {
      if (!arr || arr.length === 0) return '未設定';
      const d = getFormatDate();
      if (arr.every((e) => d === e)) return '當天';
      else return arr[0] + ' ~ ' + arr[1];
    };
    //彈窗
    const { dialogTemplate, openDialog } = useDialog();
    return {
      mapAvailableStatus,
      getSearchCondition,
      dialogTemplate,
      openDialog,
      getDateText,
      consumer_options,
      redeem_status_options,
    };
  },
};
</script>
<style lang="scss" scoped>
.search-condition-wrap {
  @include white-bg;
  display: flex;
  align-items: center;
  padding: 16px 133px 0 22px;
  position: relative;
  .button-wrap {
    position: absolute;
    top: 16px;
    right: 22px;
  }
}
</style>
